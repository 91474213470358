<template>
  <h3>DataTypes</h3>
</template>

<script>

export default {
  name: "DataTypes",
};
</script>
